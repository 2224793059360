import React from 'react';

export default function Spinner() {
	return (
		<section className="hero is-fullheight">
			<div className="hero-body">
				<div className="container">
					<div className="columns is-centered">
						<div className="column is-one-third has-text-centered">
							<span className="icon is-large has-text-danger">
								<i className="fas fa-gem fa-5x fa-inverse" />
							</span>
							<br />
							<br />
							<p className="title is-4">One moment, please...</p>
							<progress className="progress is-small is-dark" max="100" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
