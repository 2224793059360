import React from "react";
import UserAvatar from "react-user-avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import numeral from "numeral";

import Toolbar from "components/ui/Toolbar";

import { Icon } from "components/ui/bulma";
export default function Hero({
  color = "light",
  icon,
  avatar,
  title,
  subtitle,
  price,
  status,
  tabs,
  activeTab,
  setTab,
  view,
  setView,
  toolbar,
  item
}) {
  return (
    <section className={`hero is-${color} is-small is-bold box`}>
      {/* {toolbar && (
        <div className="hero-head">
          <nav className="level">
            <div className="level-left" />

            <div className="level-right">
              <Toolbar
                itemID={item}
                buttons={toolbar}
                color="primary"
                size="large"
              />
            </div>
          </nav>
        </div>
      )} */}
      <div className="hero-body">
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              {icon && <FontAwesomeIcon icon={icon} color="white" size="3x" />}
            </div>
            <div className="level-item">
              {avatar && <UserAvatar size="100" name={avatar} src={avatar} />}
            </div>
            <div className="level-item">
              <div>
                {status && (
                  <p
                    className={`tag is-medium ${
                      status === "inventory" ? "is-success" : "is-danger"
                    }`}
                  >
                    {status}
                  </p>
                )}
                {price && (
                  <>
                    <p
                      className={`tag is-medium is-black has-text-weight-bold`}
                    >
                      {`$${numeral(price).format("0,0")}`}
                    </p>
                    <br />
                    <br />
                  </>
                )}

                <p className="title">{title}</p>
                <p className="subtitle">{subtitle}</p>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="hero-foot">
        <nav className="level">
          <div className="level-left">
            {tabs && (
              <Tabs
                tabs={tabs}
                color={color}
                active={activeTab}
                setTab={setTab}
              />
            )}
          </div>

          <div className="level-right">
            {toolbar && (
              <Toolbar itemID={item} buttons={toolbar} color="primary" />
            )}
          </div>
        </nav>
      </div>
    </section>
  );
}

const Tabs = ({ color, tabs, active, setTab }) => (
  <div className="field has-addons">
    {tabs.map(tab => (
      <p className="control" key={tab.value}>
        <button
          className={`button is-${color} is-small ${
            active === tab.title ? "is-active" : ""
          }`}
          onClick={() => setTab(tab.value)}
        >
          <span className={`icon is-medium`}>
            <i className={`fas fa-${tab.icon}`} />
          </span>
          <span className={`has-text-weight-semibold is-capitalized`}>
            {tab.title}
          </span>
        </button>
      </p>
    ))}
  </div>
);

// ${active === tab.title ? 'has-text-dark' : ''}

const ToggleViewBar = ({ view, color, setView }) => (
  <div className="field has-addons">
    <p className="control">
      <button className={`button is-${color}`} onClick={() => setView("large")}>
        <FontAwesomeIcon icon="address-card" color="white" size="1x" />
      </button>
    </p>
    <p className="control">
      <button className={`button is-${color}`} onClick={() => setView("grid")}>
        <FontAwesomeIcon icon="th-large" color="white" size="1x" />
      </button>
    </p>
    <p className="control">
      <button className={`button is-${color}`} onClick={() => setView("table")}>
        <FontAwesomeIcon icon="th" color="white" size="1x" />
      </button>
    </p>
  </div>
);
