import React, { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import Hero from 'components/ui/Hero';
import List from 'components/ui/List';
import Invoice from 'components/profiles/Invoice';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

export default function Orders({ itemID }) {
	const [ view, setView ] = useState('large');
	const [ tab, setTab ] = useState('paid');
	const [ item, setItem ] = useState(itemID);
	const { loading, data, error } = useQuery(ORDERS, {
		variables: { status: tab }
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	if (item) return <Invoice itemID={item} />;

	return (
		<div>
			<Hero
				color="light"
				icon="paperclip"
				title="Orders"
				// subtitle="All your invoices with their payment statuses"
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
			/>
			<List type="invoices" view={view} data={data.invoices} actions={[ setItem ]} />
		</div>
	);
}

const tabs = [
	{ title: 'paid', icon: 'calendar-check' },
	{ title: 'past due', icon: 'calendar-times' },
	{ title: 'pending', icon: 'clock' },
	{ title: 'canceled', icon: 'trash-alt' }
];

// *****************************************
// **************** GraphQL ****************
// *****************************************

const ORDERS = gql`
	query Orders($status: String) {
		orders(status: $status) {
			id
			status
			amount
			consumer {
				id
			}
		}
	}
`;

// export default gql`
// 	mutation ChargeInvoice($invoiceID: ID!) {
// 		chargeInvoice(invoiceID: $invoiceID) {
// 			id
// 			amount
// 			status
// 		}
// 	}
// `;
