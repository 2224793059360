const firebaseConfig = {
	apiKey: 'AIzaSyCUg1yLF6eUszxWCXTqX0ZWBWlSUuKFbS0',
	authDomain: 'ruby-business.firebaseapp.com',
	databaseURL: 'https://ruby-business.firebaseio.com',
	projectId: 'ruby-business',
	storageBucket: 'ruby-business.appspot.com',
	messagingSenderId: '568824778097',
	appId: '1:568824778097:web:e73f1f022016b3ea6f3ad0'
};

export default firebaseConfig;
