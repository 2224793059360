import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useModal } from 'react-modal-hook';

import numeral from 'numeral';
import { Sparklines, SparklinesLine, SparklinesSpots, SparklinesNormalBand } from 'react-sparklines';

import { Button, Notification } from 'components/ui/bulma';

import Error from 'components/ui/Error';
import Spinner from 'components/ui/Spinner';

import customerName from 'utils/customerName';
import formatDate from 'utils/formatDate';

const CLOUDINARY = process.env.REACT_APP_CLOUDINARY_URI;

export default function Dashboard({ navigate }) {
	const { data, loading, error } = useQuery(MERCHANT);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const { merchant: { totalVolume, avgTransaction, paymentCount, incomingCash, payments } } = data;
	localStorage.setItem('logins', '1');

	return (
		<div>
			<div className="tile is-ancestor">
				<div className="tile is-parent">
					<article className="tile is-child box">
						<p className="title">
							<small>$</small>
							{totalVolume ? numeral(totalVolume).format('0,0') : 0}
						</p>
						<p className="subtitle">Total Sales</p>
					</article>
				</div>
				<div className="tile is-parent">
					<article className="tile is-child box">
						<p className="title">{paymentCount ? numeral(paymentCount).format('0,0') : 0}</p>
						<p className="subtitle">Orders</p>
					</article>
				</div>
				<div className="tile is-parent">
					<article className="tile is-child box">
						<p className="title">
							<small>$</small>
							{avgTransaction ? numeral(avgTransaction).format('0,0') : 0}
						</p>
						<p className="subtitle">Order Value</p>
					</article>
				</div>

				<div className="tile is-parent">
					<article className="tile is-child box">
						<p className="title">-</p>
						<p className="subtitle">Revenue</p>
					</article>
				</div>
				<div className="tile is-parent">
					<article className="tile is-child box">
						<p className="title">
							<small>$</small>
							{incomingCash ? numeral(incomingCash).format('0,0') : 0}
						</p>
						<p className="subtitle">Incoming cash*</p>
					</article>
				</div>
			</div>

			<div className="tile is-ancestor">
				<div className="tile is-vertical is-12">
					<div className="tile">
						<div className="tile is-parent is-vertical">
							<article className="tile is-child notification is-dark">
								{/* <p className="title">Cars {carCount}</p> */}
								<p className="title is-4">Customers</p>
								<Sparklines data={[ 5, 10, 5, 20, 1, 2, 5, 8, 1, 10, 15, 18, 20 ]} height={20}>
									<SparklinesLine color="white" />
									<SparklinesSpots style={{ fill: '#FFF' }} />
								</Sparklines>
							</article>
							<article className="tile is-child notification is-light">
								<p className="title is-4">Orders</p>
								<Sparklines data={[ 5, 10, 5, 20, 1, 2, 5, 8, 1, 10, 15, 18, 20 ]} height={20}>
									<SparklinesLine color="black" />
									<SparklinesSpots style={{ fill: '#000' }} />
								</Sparklines>
							</article>
							<article className="tile is-child notification is-white">
								<p className="title is-4">Order Value</p>
								<Sparklines data={[ 5, 10, 5, 20, 1, 2, 5, 8, 1, 10, 15, 18, 20 ]} height={20}>
									<SparklinesLine color="black" />
									<SparklinesSpots style={{ fill: '#000' }} />
								</Sparklines>
							</article>
							<article className="tile is-child notification is-danger">
								<p className="title is-4">Declined payments</p>
								<Sparklines data={[ 5, 10, 5, 20, 1, 2, 5, 8, 1, 10, 15, 18, 20 ]} height={20}>
									<SparklinesLine color="white" />
									<SparklinesSpots style={{ fill: '#FFF' }} />
								</Sparklines>
							</article>
						</div>

						<div className="tile is-parent">
							<article className="tile is-child notification is-white">
								<p className="title">Orders</p>

								<div className="content">
									<div className="content">{/* <TopPromoters consumers={consumers} /> */}</div>
								</div>
							</article>
						</div>
						<div className="tile is-parent">
							<article className="tile is-child notification is-white">
								<p className="title">Transactions</p>

								<div className="content">
									<Activity data={data} />
								</div>
							</article>
						</div>
					</div>

					<div className="tile is-parent">
						<article className="tile is-child notification is-light">
							<p className="title">Customer messages</p>

							{/* <PendingPosts /> */}
							<p>Nothing here yet</p>
						</article>
					</div>
				</div>
			</div>
		</div>
	);
}

const Activity = ({ data }) => {
	const buttons = [ { link: 'activity', icon: 'search-plus', color: 'primary' } ];
	return (
		<div>
			{data.merchant.payments.map(({ id, status, amount, date, consumer }) => (
				<div key={id}>
					<div className="box">
						<article className="media">
							<figure className="media-left">
								<span className="title is-5">
									<small>$</small>
									{amount}
								</span>
								<br />
								{status === 'declined' && <span className="tag is-danger">declined</span>}
							</figure>
							<div className="media-content">
								<div className="content">
									<strong>{customerName(consumer)}</strong>
									<br />
									<small>{formatDate(date)}</small>
									{/* <Toolbar itemID={id} buttons={buttons} color="primary" size="small" /> */}
								</div>
							</div>
						</article>
					</div>
				</div>
			))}
		</div>
	);
};

const MERCHANT = gql`
	query Merchant {
		merchant {
			id
			incomingCash
			paymentCount
			totalVolume
			avgTransaction
			approvedCount
			declinedCount
			payments {
				id
				date
				amount
				status
				isLive
				consumer {
					id
					firstName
					lastName
					email
				}
			}
		}
	}
`;
