import 'react-table/react-table.css';
import React, { useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Formik, Form, FastField } from 'formik';
import { object, string } from 'yup';
import { format } from 'date-fns';

import { Box, Button } from 'components/ui/bulma/elements';
import { Date as DateInput, Input } from 'components/ui/bulma';

import CustomerLookup from 'components/ui/CustomerLookup';
import Modal from 'components/ui/Modal';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// import customerName from 'utils/customerName';

export default function ScheduleForm({ item, onClose }) {
	const [ frequency, setFrequency ] = useState(item ? item.frequency : '');
	const [ frequencyError, setFrequencyError ] = useState(false);

	const [ createScheduled, { data, loading, error } ] = useMutation(CREATE_SCHEDULED, {
		refetchQueries: [ 'merchant', 'Subscriptions' ]
	});

	const [ update, { data: updateData, loading: updateLoading, error: updateError } ] = useMutation(UPDATE, {
		refetchQueries: [ 'merchant', 'Subscriptions', 'SubscriptionDetails' ]
	});

	if (error) return <Error />;
	if (loading)
		return (
			<div className="columns is-mobile is-centered">
				<div className="column is-half">
					<Box>
						<Spinner />
					</Box>
				</div>
			</div>
		);

	if (data && data.createSubscription && data.createSubscription.id)
		return (
			<div className="container">
				<div className="columns is-centered">
					<div className="column is-half has-text-centered">
						<Box>
							<span className="icon is-large has-text-success">
								<i className="fas fa-check-circle fa-3x" />
							</span>

							<br />
							<h1 className="title is-3">Your scheduled payment has been created.</h1>
							<button onClick={onClose} className="button is-large is-fullwidth">
								Close
							</button>
						</Box>
					</div>
				</div>
			</div>
		);

	if (updateData)
		return (
			<div className="container">
				<div className="columns is-centered">
					<div className="column is-half has-text-centered">
						<Box>
							<span className="icon is-large has-text-success">
								<i className="fas fa-check-circle fa-3x" />
							</span>

							<br />
							<h1 className="title is-3">Your scheduled payment has been updated.</h1>
							<button onClick={onClose} className="button is-large is-fullwidth">
								Close
							</button>
						</Box>
					</div>
				</div>
			</div>
		);

	return (
		<Formik
			initialValues={{
				customer: item ? item.consumer : {},
				amount: item ? item.amount : '',
				startDate: item ? item.nextRun : format(new Date(), 'yyyy-MM-dd'),
				stopDate: '',
				description: item ? item.description : ''
			}}
			validationSchema={validationSchema}
			onSubmit={({ customer: { id }, amount, description, startDate, stopDate }) => {
				if (frequency) {
					if (!item) {
						const subscriptionProps = {
							consumer: id,
							amount: amount.toString(),
							currency: 'USD',
							description,
							frequency,
							startDate,
							stopDate: stopDate || null
							// runLimit
						};

						createScheduled({
							variables: {
								subscriptionProps
							}
						});
					} else {
						const subscriptionProps = {
							id: item.id,
							consumer: id,
							amount: amount.toString(),
							currency: 'USD',
							description,
							frequency,
							startDate,
							stopDate: stopDate || null
							// runLimit
						};

						update({
							variables: {
								subscriptionProps
							}
						});
					}
				} else {
					setFrequencyError(true);
				}
			}}
		>
			{({ isSubmitting, values, errors }) => (
				<Modal icon="credit-card" title="Scheduled payment" onClose={onClose}>
					<Form>
						<FastField
							name="customer"
							label="Select a customer"
							icon="user"
							size="large"
							component={CustomerLookup}
							placeholder="Type a name or email"
						/>

						<FastField
							type="number"
							name="amount"
							label="Amount"
							icon="dollar-sign"
							size="large"
							component={Input}
							placeholder="Amount"
						/>
						<br />

						<div className="field is-centered">
							<label className="label is-large">How often?</label>
							<div className="control">
								<div className="buttons has-addons">
									<span
										className={`button is-large ${frequency === 'week' ? 'is-primary' : ''}`}
										onClick={() => setFrequency('week')}
									>
										Weekly
									</span>
									<span
										className={`button is-large ${frequency === 'bi-weekly' ? 'is-primary' : ''}`}
										onClick={() => setFrequency('bi-weekly')}
									>
										2 Weeks
									</span>
									<span
										className={`button is-large ${frequency === 'monthly' ? 'is-primary' : ''}`}
										onClick={() => setFrequency('monthly')}
									>
										Monthly
									</span>
									<span
										className={`button is-large ${frequency === 'quarterly' ? 'is-primary' : ''}`}
										onClick={() => setFrequency('quarterly')}
									>
										Quarterly
									</span>
								</div>
							</div>
						</div>
						{frequencyError && <p className="help is-danger">Please select the payment frequency!</p>}
						<br />

						<FastField
							name="description"
							label="What is it for?"
							icon="info-circle"
							size="large"
							component={Input}
							placeholder="Example: Monthly service"
						/>

						<br />

						<FastField
							name="startDate"
							label="Start on"
							icon="calendar"
							size="large"
							component={DateInput}
							placeholder="Frequency"
						/>
						{/* <FastField
									name="stopDate"
									label="Stop after (optional)"
									icon="calendar"
									component={DateInput}
									placeholder="Frequency"
								/> */}

						<hr />
						<Button type="submit" block size="large" icon="check-circle" disabled={isSubmitting}>
							{!item ? 'Schedule payment' : 'Save changes'}
						</Button>
					</Form>
				</Modal>
			)}
		</Formik>
	);
}

const validationSchema = object().shape({
	customer: string().required('Please choose a customer'),
	amount: string().required('Please enter amount'),
	description: string().required('Please enter description'),
	startDate: string().required('Please enter a start date')
});

const CREATE_SCHEDULED = gql`
	mutation CreateSubscription($subscriptionProps: SubscriptionProps) {
		createSubscription(subscriptionProps: $subscriptionProps) {
			id
			status
			amount
			nextRun
		}
	}
`;

const UPDATE = gql`
	mutation UpdateSubscription($subscriptionProps: SubscriptionProps) {
		updateSubscription(subscriptionProps: $subscriptionProps) {
			id
			status
		}
	}
`;
