import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import { Router } from '@reach/router';

import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import { ModalProvider } from 'react-modal-hook';
import { CloudinaryContext } from 'cloudinary-react';

import Layout from 'components/ui/Layout';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// **** Pages ****
import Dashboard from 'pages/Dashboard';
import Customers from 'pages/Customers';
import Activity from 'pages/Activity';

import Orders from 'pages/Orders';

import Invoices from 'pages/Invoices';
import Inventory from 'pages/Inventory';
import InventoryCard from 'components/InventoryCard';
import Scheduled from 'pages/Scheduled';
import Developers from 'pages/Developers';

// **** Profiles ****
import CustomerProfile from 'components/profiles/Customer';
import InvoiceProfile from 'components/profiles/Invoice';
import ScheduledProfile from 'components/profiles/Scheduled';
import TransactionProfile from 'components/profiles/Activity';

// **** Forms ****
import AccountSetup from 'components/forms/AccountSetup';
// import Activatemerchant from 'components/forms/Activate';

export default function Account() {
	const [ user ] = useAuthState(firebaseAppAuth);

	const { loading, data, error } = useQuery(MERCHANT);

	useEffect(
		() => {
			if (user && data && data.merchant)
				window.analytics.identify(user.uid, {
					name: user.displayName,
					email: user.email,
					company: {
						id: data.merchant.id,
						name: data.merchant.name
					},
					createdAt: user.metadata.creationTime
				});

			window.analytics.page('merchant');
		},
		[ user, data ]
	);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;



	return (
		<CloudinaryContext cloudName="finhub">
			<ModalProvider>
				{data?.merchant?.status === 'new' ? (
					<AccountSetup merchantID={data.merchant.id} />
				) : (
					<Router>
						<Layout path="/">
							<Dashboard path="/" />
							<Customers path="/customers" />
							<CustomerProfile path="customers/:itemID" />

							<Invoices path="invoices" />
							<InvoiceProfile path="invoices/:itemID" />

							<Scheduled path="scheduled" />
							<ScheduledProfile path="scheduled/:itemID" />

							<Developers path="/developers" />

							<Orders path="/orders" />

							<Inventory path="/inventory" />
							<InventoryCard path="/inventory/:id" />

							<Activity path="payments" />
							<TransactionProfile path="payments/:itemID" />
						</Layout>
						<Layout default />
					</Router>
				)}
			</ModalProvider>
		</CloudinaryContext>
	);
}

const MERCHANT = gql`
	query Merchant {
		merchant {
			id
			name
			status
		}
	}
`;
