import React, { useCallback, useState } from 'react';

import axios from 'axios';
import { useDropzone } from 'react-dropzone';
// import Toolbar from "components/ui/Toolbar";
import { Progress } from 'components/ui/bulma';

const CLOUDINARY_UPLOAD = process.env.REACT_APP_CLOUDINARY_UPLOAD_URI;

export default function Upload({
	field,
	form: { touched, errors },
	creative: { uri, size, position, background },
	actions: { setUri, setSecureURL, setSize, setPosition, setBackground, setOriginalFileName, setPictures }
}) {
	// const [horizontal, setHorizontal] = useState("west");
	// const [vertical, setVertical] = useState("south");
	const [ uploadProgress, setUploadProgress ] = useState(0);

	// useEffect(() => {
	//   setPosition(`${vertical}_${horizontal}`);
	// }, [horizontal, setPosition, vertical]);

	const onDrop = useCallback((acceptedFiles) => {
		// setOriginalFileName(acceptedFiles[0].name);
		let pictures = [];
		acceptedFiles.map((file) => {
			const fd = new FormData();
			const config = {
				headers: { 'X-Requested-With': 'XMLHttpRequest' },
				onUploadProgress: (progressEvent) => {
					const progress = Math.round(progressEvent.loaded * 100.0 / progressEvent.total);
					setUploadProgress(progress);
				}
			};
			fd.append('upload_preset', 'ruby-items');
			fd.append('tags', [ 'browser_upload', 'ruby-items' ]);
			fd.append('file', file);

			axios
				.post(CLOUDINARY_UPLOAD, fd, config)
				.then(({ data: { url, secure_url, public_id, height, width, format } }) => {
					const uri = public_id.split('/');
					pictures = pictures.concat(uri[2]);
					setPictures(pictures);
				})
				.catch((err) => console.error('err', err));
		}, []);
	});

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	const help = '';

	return (
		<div>
			<div
				className={`file is-medium is-light is-${touched[field.name] && errors[field.name] && 'danger'}`}
				{...getRootProps()}
			>
				<input type="file" name="picture" {...getInputProps()} />
				<span className="file-cta">
					<span className="file-icon">
						<i className="fas fa-upload fa-lg" />
					</span>
					<span className="file-label">Click to upload or drop pictures here</span>
				</span>
			</div>

			<span>
				{uploadProgress !== 0 && uploadProgress !== 100 ? (
					<Progress value={uploadProgress} color="primary" size="small" />
				) : (
					<p className="title is-7">{help}</p>
				)}
			</span>
			<br />
			{/* <Toolbar buttons={buttons} color="primary" size="medium" /> */}
		</div>
	);
}
