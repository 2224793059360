import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Navbar from 'components/ui/Navbar';
import { Box, Button, ButtonGroup } from 'components/ui/bulma/elements';
import { Input } from 'components/ui/bulma';

export default function AccountSetup({ merchantID }) {
	// const [ userProps, setUserProps ] = useState();
	const [ merchantProps, setMerchantProps ] = useState();

	// useEffect(
	// 	() => {
	// 		if (userProps && merchantProps) {
	// 			window.analytics.track('Sign Up', {
	// 				platform: 'payment-ninja',
	// 				merchantID: merchant.id,
	// 				name: merchant.name,
	// 				userID: user.uid,
	// 				email: user.email
	// 			});

	// 			window.analytics.identify(user.uid, {
	// 				name: `${userProps.firstName} ${userProps.lastName}`,
	// 				phone: userProps.phone,
	// 				company: {
	// 					id: merchantProps.id,
	// 					name: merchantProps.name,
	// 					website: merchantProps.url,
	// 					volume: merchantProps.volume,
	// 					status: 'onboarding'
	// 				}
	// 			});
	// 		}
	// 	},
	// 	[ userProps, merchantProps ]
	// );

	const [ onboardmerchant ] = useMutation(ONBOARD_MERCHANT, {
		refetchQueries: [ 'CurrentUser' ]
	});

	// const [ updateUser ] = useMutation(UPDATE_USER);

	return (
		<div>
			<Navbar />
			<br />
			<div className="columns is-mobile is-centered">
				<div className="column is-one-third">
					<Box>
						<h1 className="title">Let's setup your account</h1>
						<Formik
							initialValues={{
								name: '',
								url: '',
								volume: '',
								fullName: '',
								phone: ''
							}}
							validationSchema={merchantSchema}
							onSubmit={({ name, url }, { setSubmitting, setStatus }) => {
								setSubmitting(true);

								const merchantProps = {
									id: merchantID,
									name,
									url
								};

								onboardmerchant({ variables: { merchantProps } });

								// setMerchantProps(merchantProps);

								setSubmitting(false);
							}}
						>
							{({
								status,
								isSubmitting,
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								touched,
								errors
							}) => (
								<Form>
									<Field
										name="name"
										label="Business Name"
										icon="store"
										size="medium"
										component={Input}
										placeholder="Business Name"
									/>
									<Field
										name="url"
										label="Subdomain"
										icon="globe"
										size="medium"
										component={Input}
										placeholder="mybiz.rubybusiness.com"
									/>

									<hr />

									<Button color="danger" type="submit" block size="medium" icon="check-circle">
										Start selling
									</Button>
								</Form>
							)}
						</Formik>
					</Box>
				</div>
			</div>
		</div>
	);
}

const merchantSchema = Yup.object().shape({
	name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	url: Yup.string().required('Please enter your subdomain')
});

const ONBOARD_MERCHANT = gql`
	mutation OnboardMerchant($merchantProps: MerchantProps) {
		onboardMerchant(merchantProps: $merchantProps) {
			id
			name
			status
		}
	}
`;

const UPDATE_USER = gql`
	mutation UpdateUser($userProps: UserProps) {
		updateUser(userProps: $userProps) {
			id
			firstName
			lastName
		}
	}
`;
