import React, { useContext, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import Hero from 'components/ui/Hero';
import Cards from 'components/ui/Cards';
import Error from 'components/ui/Error';
import Spinner from 'components/ui/Spinner';

export default function Products({ id }) {
	const [ tab, setTab ] = useState('stock');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(id);

	const { loading, data, error } = useQuery(PRODUCTS, {
		variables: { status: tab }
	});

	const [ archiveCar ] = useMutation(ARCHIVE_CAR, {
		refetchQueries: [ 'Products' ]
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const actions = [ { title: 'archive', color: 'danger', icon: 'archive', action: archiveCar } ];

	return (
		<div>
			<Hero
				color="light"
				icon="images"
				title="Products"
				// subtitle="Cars in inventory by status."
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
			/>
			<Cards type="products" data={data.products} />
		</div>
	);
}

const tabs = [
	{ title: 'In Stock', icon: 'cart-arrow-down', value: 'stock' },
	{ title: 'marked down', icon: 'dollar-sign', value: 'sale' },
	{ title: 'sold out', icon: 'times-circle', value: 'out' },
	{ title: 'archived', icon: 'archive', value: 'archived' }
];

const PRODUCTS = gql`
	query Products($status: String) {
		products(status: $status) {
			id
			name
			price {
				id
				date
				price
			}
			pictures {
				id
				uri
				featured
			}
		}
	}
`;

const ARCHIVE_CAR = gql`
	mutation ArchiveCar($id: ID!) {
		archiveCar(id: $id) {
			id
		}
	}
`;
