import React, { useCallback, useContext, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { FormikWizard } from 'formik-wizard';
import { object, string, array, number } from 'yup';

import Modal from 'components/ui/Modal';
import Wizard from 'components/ui/Wizard';

import Item from 'components/forms/Item';
import Description from 'components/forms/Description';
import Traits from 'components/forms/Traits';
import Pictures from 'components/forms/Pictures';
import Specs from 'components/forms/Specs';

import Summary from 'components/forms/Summary';

import { Hero, Button, ButtonGroup } from 'components/ui/bulma';

export default function InventoryWizard({ onClose }) {
	const [ spinner, setSpinner ] = useState(false);

	const [ createItem ] = useMutation(CREATE_PRODUCT, {
		refetchQueries: [ 'Merchant', 'Inventory' ]
	});

	const handleSubmit = useCallback(
		async ({ item, description, pictures }) => {
			setSpinner(true);

			const pictureProps = pictures.pictures.map((picture) => ({
				uri: picture
			}));

			const productProps = {
				...item,
				...description,
				pictures: pictureProps
			};

			await createItem({ variables: { productProps } });

			setSpinner(false);
			onClose();
		},
		[ createItem, onClose ]
	);

	return (
		<Modal size="8" icon="magic" title="Add Inventory" onClose={onClose}>
			<FormikWizard steps={steps} onSubmit={handleSubmit} render={FormWrapper} />
		</Modal>
	);
}

const FormWrapper = ({
	currentStep,
	steps,
	children,
	isLastStep,
	status,
	goToPreviousStep,
	canGoBack,
	actionLabel
}) => (
	<div>
		<Wizard steps={steps} currentStep={currentStep} stepProps={stepProps} />
		{children}
		<ButtonGroup right>
			<Button size="medium" icon="angle-left" color="white" action={goToPreviousStep} disabled={!canGoBack}>
				Back
			</Button>
			<Button type="submit" block size="medium" icon="check-circle">
				{actionLabel || (isLastStep ? 'Finish' : 'Continue')}
			</Button>
		</ButtonGroup>
	</div>
);

const steps = [
	// {
	// 	id: 'traits',
	// 	component: Traits,
	// 	icon: 'item',
	// 	name: 'Item',
	// 	title: 'Item Info',
	// 	subtitle: 'Make, model etc.',
	// 	initialValues: {
	// 		traits: []
	// 	}
	// 	validationSchema: object().shape({
	// 		sku: string().required(),
	// 		name: string().required(),
	// 		cost: number().required(),
	// 		price: number().required()
	// 	})
	// },

	{
		id: 'item',
		component: Item,
		icon: 'item',
		name: 'Item',
		title: 'Item Info',
		subtitle: 'Make, model etc.',
		initialValues: {
			sku: '',
			name: '',
			cost: '',
			price: ''
		},
		validationSchema: object().shape({
			sku: string().required(),
			name: string().required(),
			cost: number().required(),
			price: number().required()
		})
	},
	{
		id: 'description',
		component: Description,
		icon: 'item',
		name: 'Item',
		title: 'Item Info',
		subtitle: 'Make, model etc.',
		initialValues: {
			description: ''
		},
		validationSchema: object().shape({
			description: string().required()
		})
	},
	{
		id: 'pictures',
		component: Pictures,
		icon: 'images',
		name: 'Pictures',
		title: 'Upload pictures',
		subtitle: 'Upload pictures',
		initialValues: {
			pictures: []
		},
		validationSchema: object().shape({
			pictures: array().required('Please upload your pictures').min(1, 'Min. 1 picture is required')
		})
	}
	// {
	// 	id: 'summary',
	// 	component: Summary
	// }
];

const stepProps = [
	{
		id: 'item',
		icon: 'box-open',
		name: 'Item',
		title: 'Item Info',
		subtitle: 'Main info'
	},

	{
		id: 'description',
		icon: 'keyboard',
		name: 'Description',
		title: 'Description',
		subtitle: 'Detailed item specs'
	},
	// {
	// 	id: 'traits',
	// 	icon: 'list',
	// 	name: 'Traits',
	// 	title: 'Traits',
	// 	subtitle: 'Detailed item specs'
	// },
	{
		id: 'pictures',
		icon: 'images',
		name: 'Pictures',
		title: 'Upload Pictures',
		subtitle: 'Please upload your item pictures'
	}
	// {
	// 	id: 'summary',
	// 	icon: 'clipboard-list',
	// 	name: 'Summary',
	// 	title: 'Summary',
	// 	subtitle: 'Please check if all is correct. Feel free to go back and change anything.'
	// }
];

const CREATE_PRODUCT = gql`
	mutation CreateProduct($productProps: ProductProps) {
		createProduct(productProps: $productProps) {
			id
		}
	}
`;
