import React, { useContext, useRef, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useModal } from 'react-modal-hook';
import { Formik, Form, FastField } from 'formik';
import { Image } from 'cloudinary-react';
import numeral from 'numeral';
import { object, string, array, number } from 'yup';

import {
	Box,
	Button,
	Field,
	Level,
	Item,
	Input,
	Card,
	CardHeader,
	CardFooter,
	CardImage,
	Column,
	Columns,
	Notification
} from 'components/ui/bulma';

// import CustomerForm from "components/forms/CustomerForm";
// import PaymentForm from "components/forms/PaymentForm";

import UploadPictures from 'components/ui/UploadPictures';
import Hero from 'components/ui/Hero';
import Cards from 'components/ui/Cards';
// import List from "components/ui/List";
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// import brandIcon from "utils/brandIcon";
// import formatDate from "utils/formatDate";
// import setColor from "utils/setColor";
// import statusIcon from "utils/statusIcon";
// import customerName from "utils/customerName";

export default function IventoryCard({ id }) {
	const componentRef = useRef();

	const [ tab, setTab ] = useState('overview');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(id);

	const { data, loading, error } = useQuery(ITEM, {
		variables: { id }
	});

	const [ sell ] = useMutation(SELL_CAR, {
		variables: { id },
		refetchQueries: [ 'Inventory, Item' ]
	});

	const [ archive ] = useMutation(ARCHIVE_CAR, {
		variables: { id },
		refetchQueries: [ 'Inventory, Item' ]
	});

	const [ restock ] = useMutation(ADD_INVENTORY_CAR, {
		variables: { id },
		refetchQueries: [ 'Inventory, Item' ]
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const { car, car: { make, model, year, status, price, mileage, pictures } } = data;

	const buttons = [
		{
			action: sell,
			icon: 'credit-card',
			text: 'Sell',
			color: 'success',
			disabled: status === 'archived'
		},

		{
			action: archive,
			icon: 'archive',
			text: 'Archive',
			color: 'danger',
			disabled: status === 'archived'
		},
		{
			action: restock,
			icon: 'store',
			text: 'Re-stock',
			color: 'dark',
			disabled: status === 'archived'
		}
	];

	return (
		<div>
			<Hero
				item={item}
				price={price}
				color="light"
				title={`${year} ${make} ${model}`}
				subtitle={`${numeral(mileage).format('0,0')} ODO`}
				status={status}
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
				toolbar={buttons}
			/>

			<Profile tab={tab} actions={[ setItem ]} view={view} data={data} />
		</div>
	);
}

const Profile = ({ tab, view, actions: [ setItem ], data: { car } }) => {
	switch (tab) {
		case 'interested':
			return <Interested car={car} />;

		case 'test-drives':
			return <TestDrives car={car} />;

		case 'specs':
			return <Specs car={car} />;

		case 'pictures':
			return <Pictures car={car} />;

		default:
			return <Overview car={car} />;
	}
};

const tabs = [
	{ title: 'overview', icon: 'address-card' },
	{ title: 'interested', icon: 'user-friends' },
	{ title: 'test-drives', icon: 'calendar-check' },
	{ title: 'specs', icon: 'car' },
	{ title: 'pictures', icon: 'image' }
];

export const Overview = ({ car }) => {
	const [ update ] = useMutation(UPDATE_CAR, {
		refetchQueries: [ 'Inventory, Item' ]
	});
	return (
		<div>
			<Box>
				<nav className="level">
					<div className="level-item has-text-centered">
						<div>
							<p className="heading">Views</p>
							<p className="title">0</p>
						</div>
					</div>
					<div className="level-item has-text-centered">
						<div>
							<p className="heading">Wishlist</p>
							<p className="title">
								{/* {consumer.totalVolume ? ( */}
								<span>0{/* {Number(consumer.totalVolume).toFixed(2)} */}</span>
								{/* ) : (
                    "0"
                  )} */}
							</p>
						</div>
					</div>

					<div className="level-item has-text-centered">
						<div>
							<p className="heading">Test-drives</p>
							<p className="title">
								<span>0</span>
								{/* ) : ( "-" )} */}
							</p>
						</div>
					</div>

					<div className="level-item has-text-centered">
						<div>
							<p className="heading">Latest</p>
							<p className="title">
								{/* {consumer.latestTransaction ? ( */}
								<span>0</span>
								{/* ) : (
                    "-"
                  )} */}
							</p>
						</div>
					</div>
				</nav>
			</Box>

			<Formik
				initialValues={{
					id: car.id,
					make: car.make,
					model: car.model,
					trim: car.trim,
					year: car.year,
					mileage: car.mileage,
					price: car.price,
					vin: car.vin
				}}
				validationSchema={object().shape({
					make: string().required("Please enter the car's make"),
					model: string().required("Please enter the car's model"),
					trim: string().required("Please enter the car's trim"),
					year: string().required("Please enter the car's year"),
					mileage: string().required("Please enter the car's mileage"),
					price: string().required("Please enter the car's price"),
					vin: string().required("Please enter the car's VIN")
				})}
				onSubmit={(values, { setSubmitting }) => {
					update({ variables: { carProps: { ...values } } });
				}}
			>
				<Form>
					<Box>
						<Field horizontal>
							<FastField
								name="vin"
								label="VIN"
								icon="database"
								component={Input}
								placeholder="VIN"
								help="VIN"
							/>
							<FastField
								type="number"
								name="year"
								label="Year"
								icon="calendar-check"
								component={Input}
								placeholder="Year"
								help="Year"
							/>
							<FastField
								name="make"
								label="Make"
								icon="car"
								component={Input}
								placeholder="Make"
								help="Make"
							/>
							<FastField
								name="model"
								label="Model"
								icon="car-side"
								component={Input}
								placeholder="Model"
								help="Model"
							/>

							<FastField
								name="trim"
								label="Trim"
								icon="calendar-check"
								component={Input}
								placeholder="Trim"
								help="Trim"
							/>
						</Field>

						<Field horizontal>
							<FastField
								size="large"
								type="number"
								name="mileage"
								label="Mileage"
								icon="tachometer-alt"
								component={Input}
								placeholder="Mileage"
								help="Mileage"
							/>
							<FastField
								size="large"
								type="number"
								name="price"
								label="Price"
								icon="dollar-sign"
								component={Input}
								placeholder="Price"
								help="Price"
							/>
						</Field>
						<Button type="submit" color="danger">
							Save
						</Button>
					</Box>
				</Form>
			</Formik>
		</div>
	);
};

export const Interested = () => (
	<div>
		<Box>
			<nav className="level">
				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Views</p>
						<p className="title">0</p>
					</div>
				</div>
				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Wishlist</p>
						<p className="title">
							{/* {consumer.totalVolume ? ( */}
							<span>0{/* {Number(consumer.totalVolume).toFixed(2)} */}</span>
							{/* ) : (
                    "0"
                  )} */}
						</p>
					</div>
				</div>

				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Test-drives</p>
						<p className="title">
							<span>0</span>
							{/* ) : ( "-" )} */}
						</p>
					</div>
				</div>

				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Latest</p>
						<p className="title">
							{/* {consumer.latestTransaction ? ( */}
							<span>0</span>
							{/* ) : (
                    "-"
                  )} */}
						</p>
					</div>
				</div>
			</nav>
		</Box>
		{/* <Box>
            <Columns>
              <Column>
                <p className="title">Viewed</p>
              </Column>
              <Column>sdfsdf</Column>
              <Column>sdfsdf</Column>
              <Column>sdfsdf</Column>
            </Columns>
          </Box> */}
	</div>
);
export const TestDrives = () => (
	<div>
		<Box>
			<nav className="level">
				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Views</p>
						<p className="title">0</p>
					</div>
				</div>
				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Wishlist</p>
						<p className="title">
							{/* {consumer.totalVolume ? ( */}
							<span>0{/* {Number(consumer.totalVolume).toFixed(2)} */}</span>
							{/* ) : (
                    "0"
                  )} */}
						</p>
					</div>
				</div>

				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Test-drives</p>
						<p className="title">
							<span>0</span>
							{/* ) : ( "-" )} */}
						</p>
					</div>
				</div>

				<div className="level-item has-text-centered">
					<div>
						<p className="heading">Latest</p>
						<p className="title">
							{/* {consumer.latestTransaction ? ( */}
							<span>0</span>
							{/* ) : (
                    "-"
                  )} */}
						</p>
					</div>
				</div>
			</nav>
		</Box>
		{/* <Box>
            <Columns>
              <Column>
                <p className="title">Viewed</p>
              </Column>
              <Column>sdfsdf</Column>
              <Column>sdfsdf</Column>
              <Column>sdfsdf</Column>
            </Columns>
          </Box> */}
	</div>
);

const Specs = ({ car }) => {
	const [ update ] = useMutation(UPDATE_CAR, {
		refetchQueries: [ 'Inventory, Item' ]
	});

	return (
		<div>
			<Formik
				initialValues={{
					id: car.id,
					body: car.body,
					doors: car.doors,
					engine: car.engine,
					transmission: car.transmission,
					drive: car.drive,
					exterior: car.exterior,
					interior: car.interior,
					fuel: car.fuel,
					mpgCity: car.mpgCity,
					mpgHighway: car.mpgHighway
				}}
				onSubmit={(values, { setSubmitting }) => {
					update({ variables: { carProps: { ...values } } });
				}}
			>
				<Form>
					<Box>
						<Field horizontal>
							<FastField
								name="body"
								label="Body"
								icon="car-side"
								component={Input}
								placeholder="Body"
								help="Body"
								value="SUV"
							/>
							<FastField
								type="number"
								name="doors"
								label="Doors"
								icon="car-side"
								component={Input}
								placeholder="Doors"
								help="Doors"
							/>
						</Field>
						<Field horizontal>
							<FastField
								name="engine"
								label="Engine"
								icon="sun"
								component={Input}
								placeholder="Engine"
								help="Engine"
							/>
							<FastField
								name="transmission"
								label="Transmission"
								icon="cogs"
								component={Input}
								placeholder="Transmission"
								help="Transmission"
							/>
							<FastField
								name="drive"
								label="Drive"
								icon="truck-monster"
								component={Input}
								placeholder="Drive"
								help="Drive"
							/>
						</Field>
						<Field horizontal>
							<FastField
								name="exterior"
								label="Exterior"
								icon="car-side"
								component={Input}
								placeholder="Exterior"
								help="Exterior"
							/>
							<FastField
								name="interior"
								label="Interior"
								icon="palette"
								component={Input}
								placeholder="Interior"
								help="Interior"
							/>
						</Field>
						<Field horizontal>
							<FastField
								name="fuel"
								label="Fuel"
								icon="gas-pump"
								component={Input}
								placeholder="Fuel"
								help="Fuel"
							/>
							<FastField
								type="number"
								name="mpgCity"
								label="MPG City"
								icon="city"
								component={Input}
								placeholder="MPG City"
								help="MPG City"
							/>
							<FastField
								type="number"
								name="mpgHighway"
								label="MPG Highway"
								icon="road"
								component={Input}
								placeholder="MPG Highway"
								help="MPG Highway"
							/>
						</Field>
						<Button type="submit" color="danger">
							Save
						</Button>
					</Box>
				</Form>
			</Formik>
		</div>
	);
};
const Pictures = ({ car, action }) => {
	const [ removePicture ] = useMutation(REMOVE_PICTURE, {
		refetchQueries: [ 'Item', 'Inventory' ]
	});
	return (
		<div>
			<UploadPictures itemID={car.id} />
			<Columns multiline>
				{car.pictures.map((item) => (
					<Column size="3" key={item.id}>
						<Card>
							<CardImage>
								<Image publicId={`caragents/${item.uri}`} width="200" />
							</CardImage>
							<Button
								block
								icon="trash-alt"
								color="dark"
								action={() => removePicture({ variables: { id: item.id } })}
							>
								Delete
							</Button>
						</Card>
					</Column>
				))}
			</Columns>
		</div>
	);
};

// *****************************************
// **************** GraphQL ****************
// *****************************************

const ITEM = gql`
	query Item($id: ID!) {
		car(id: $id) {
			id
			make
			model
			year
			status
			mileage
			price
			trim
			body
			exterior
			interior
			engine
			transmission
			drive
			fuel
			mpgCity
			mpgHighway
			doors
			vin
			featured
			pictures {
				id
				uri
				featured
			}
		}
	}
`;

const UPDATE_CAR = gql`
	mutation UpdateCar($carProps: CarProps) {
		updateCar(carProps: $carProps) {
			id
		}
	}
`;

const REMOVE_PICTURE = gql`
	mutation RemovePicture($id: ID!) {
		removePicture(id: $id) {
			id
		}
	}
`;

const ARCHIVE_CAR = gql`
	mutation ArchiveCar($id: ID!) {
		archiveCar(id: $id) {
			id
		}
	}
`;

const ADD_INVENTORY_CAR = gql`
	mutation AddInventoryCar($id: ID!) {
		addInventoryCar(id: $id) {
			id
		}
	}
`;

const SELL_CAR = gql`
	mutation SellCar($id: ID!) {
		sellCar(id: $id) {
			id
		}
	}
`;
