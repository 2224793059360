import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useModal } from 'react-modal-hook';
import { Link } from '@reach/router';

import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import Error from 'components/ui/Error';
import Spinner from 'components/ui/Spinner';

// import { Button } from 'components/ui/bulma/elements';

import InventoryWizard from '../forms/InventoryWizard';

export default function Navbar({ extendedMenu, extendMenu }) {
	const [ user ] = useAuthState(firebaseAppAuth);
	const { loading, data, error } = useQuery(MERCHANT);

	const [ showInventoryWizard, hideInventoryWizard ] = useModal(() => (
		<div className="modal is-active">
			<div className="modal-background" />
			<div className="modal-content">
				<InventoryWizard onClose={hideInventoryWizard} />
			</div>
		</div>
	));

	if (loading) return null;
	if (error) return <Error error={error} />;

	return (
		<nav className="navbar" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				<span className="icon is-large has-text-light" onClick={() => extendMenu(!extendedMenu)}>
					<span className="fa-stack fa-lg">
						<i className="fas fa-square fa-stack-2x" />
						<i className="fas fa-bars fa-stack-1x has-text-grey" />
					</span>
				</span>
				<Link to="/">
					<span className="icon is-large has-text-black">
						<span className="fa-stack fa-lg">
							<i className="fas fa-square fa-stack-2x" />
							<i className="fas fa-gem fa-stack-1x has-text-danger" />
						</span>
					</span>
				</Link>
				<div className="navbar-item">
					<Link to="/">
						<p className="title is-4">{data.merchant.name}</p>
					</Link>
				</div>
			</div>

			<div id="navbarBasicExample" className="navbar-menu">
				<div className="navbar-start" />

				<div className="navbar-item">
					<button className="button is-danger" onClick={showInventoryWizard}>
						<span className="icon">
							<i className="fas fa-plus-circle" aria-hidden="true" />
						</span>
						<span>
							<strong>Add Product</strong>
						</span>
					</button>
				</div>

				<div className="navbar-item">
					<div className="dropdown is-hoverable is-right" aria-haspopup="true" aria-controls="dropdown-menu4">
						<span className="icon dropdown-trigger">
							<i className="fas fa-2x fa-user-circle has-text-grey" aria-hidden="true" />
							<i className="fas fa-angle-down" aria-hidden="true" />
						</span>

						<div className="dropdown-menu" id="dropdown-menu4" role="menu">
							<div className="dropdown-content">
								<span className="dropdown-item">{user && user.email}</span>

								<hr className="dropdown-divider" />
								<a className="dropdown-item" onClick={() => firebaseAppAuth.signOut()}>
									<strong>Log Out</strong>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="navbar-item"> </div>
			</div>
		</nav>
	);
}

const MERCHANT = gql`
	query Merchant {
		merchant {
			id
			name
			status
		}
	}
`;
