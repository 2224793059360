import React, { useEffect, useState } from 'react';
import { FastField, useFormikContext } from 'formik';

import { Field, Level, Item } from 'components/ui/bulma';
import { Input } from 'components/ui/bulma';

export default function Brand() {
	const [ loading, setLoading ] = useState(false);
	const { values, setValues, setFieldTouched } = useFormikContext();

	return (
		<div>
			<FastField
				name="name"
				size="medium"
				label="Make"
				icon="box-open"
				component={Input}
				placeholder="Item name"
				help="Item name"
			/>
			<FastField
				name="sku"
				size="medium"
				label="SKU"
				icon="warehouse"
				component={Input}
				placeholder="SKU"
				help="SKU"
			/>
			<Field horizontal>
				<FastField
					name="cost"
					size="medium"
					label="Buying Cost"
					icon="dollar-sign"
					component={Input}
					placeholder="Product Purchase Cost"
					help="How much do you pay to the item's supplier?"
				/>
				<FastField
					name="price"
					size="medium"
					label="Retail Price"
					icon="dollar-sign"
					component={Input}
					placeholder="Retail Price"
					help="Retail price"
				/>
			</Field>
		</div>
	);
}
