import React, { useState, useEffect } from 'react';

import { FastField, useFormikContext } from 'formik';
import { Image } from 'cloudinary-react';

import Upload from 'components/ui/Upload';

import { Columns, Column, Box } from 'components/ui/bulma';

export default function Pictures() {
	const [ pictures, setPictures ] = useState([]);
	const [ uri, setUri ] = useState();
	const [ secureURL, setSecureURL ] = useState();
	const [ size, setSize ] = useState(0.25);
	const [ position, setPosition ] = useState();
	const [ background, setBackground ] = useState(true);
	const [ originalFileName, setOriginalFileName ] = useState();

	const { values, setFieldValue } = useFormikContext();

	const creative = { uri, size, position, background, originalFileName };
	const actions = {
		setPictures
	};

	useEffect(
		() => {
			if (pictures) {
				setFieldValue('pictures', pictures);
			}
		},
		[ pictures, setFieldValue ]
	);

	return (
		<Box>
			<FastField
				name="creative"
				label="Upload an artwork"
				icon="image"
				component={Upload}
				creative={creative}
				actions={actions}
			/>

			<Columns multiline>
				{pictures.map((picture) => (
					<Column narrow key={picture} box>
						<figure className="image">
							<Image publicId={`ruby/items/${picture}`} dpr="auto" responsive width="100" crop="fit" />
						</figure>
					</Column>
				))}
			</Columns>
		</Box>
	);
}
