import React from 'react';
import { Link } from '@reach/router';

import {
	Box,
	Card,
	CardHeader,
	Media,
	CardContent,
	CardImage,
	CardFooter,
	Column,
	Button,
	Notification,
	CardFooterItem
} from 'components/ui/bulma';

import { Image } from 'cloudinary-react';
import UserAvatar from 'react-user-avatar';
import numeral from 'numeral';

export default function Cards({ type, data, actions }) {
	if (!data || data.length < 1)
		return (
			<Notification color="dark">
				<strong>{`No ${type} found. Please check back later!`}</strong>
			</Notification>
		);

	return (
		<div className="columns is-multiline is-mobile">
			{data.map((item) => <Item type={type} data={item} actions={actions} key={item.id} />)}
		</div>
	);
}

const Item = ({ type, data, actions }) => {
	switch (type) {
		case 'consumers':
			return (
				<div className="column is-narrow">
					<div className="card">
						<div className="card-content">
							<div className="media">
								<div className="media-left">
									<Link to={`/customers/${data.id}`}>
										<figure className="image is-48x48">
											{data.email && (
												<UserAvatar
													size="64"
													name={data.email}
													src={data.avatar}
													colors={[ '#ccc', '#aaa', '#ddd' ]}
												/>
											)}
										</figure>
									</Link>
								</div>
								<div className="media-content">
									<p className="title">{data.displayName}</p>
									<p className="subtitle">{data.email}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			);

		case 'products':
			return (
				<Column size="3">
					<Box>
						<Card>
							<CardHeader>
								<p className="title is-5">{data.name}</p>
							</CardHeader>
							<CardImage>
								<Link to={`${data.id}`}>
									<Image publicId={`ruby/items/${data.pictures[0].uri}`} />
								</Link>
							</CardImage>
							<CardContent>
								<br />
								<p className="title is-4">${numeral(data.price[0].price).format('0,0.00')}</p>
								<p className="subtitle">{/* {data.year} {data.mileage} */}</p>
								<hr />
								<Link to={`${data.id}`}>
									<Button block color="black" icon="search-plus">
										Details
									</Button>
								</Link>
							</CardContent>
							<CardFooter>
								{actions &&
									actions.map((button) => (
										<CardFooterItem key={button.icon}>
											<Button
												outlined
												block
												color={button.color}
												icon={button.icon}
												action={() => button.action({ variables: { id: data.id } })}
											>
												{button.title}
											</Button>
										</CardFooterItem>
									))}
							</CardFooter>
						</Card>
					</Box>
				</Column>
			);

		case 'pictures':
			return (
				<Column narrow>
					<Box>
						<Card>
							{/* <CardHeader>
                <p className="title">
                  {data.make} {data.model}
                </p>
              </CardHeader> */}
							<CardImage>
								<Image publicId={`caragents/${data.uri}`} width="200" />
							</CardImage>
						</Card>
					</Box>
				</Column>
			);

		default:
			break;
	}
};
