import './App.sass';
import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import analytics from 'react-segment';

import { firebaseAppAuth } from 'services/firebase';

import Auth from 'pages/AuthPage';
import Account from 'components/Account';

import Error from 'components/ui/Error';
import Spinner from 'components/ui/Spinner';

analytics.default.load(process.env.REACT_APP_SEGMENT_KEY);

export default function App() {
	const [ token, setToken ] = useState();
	const [ user, loading, error ] = useAuthState(firebaseAppAuth);

	useEffect(
		() => {
			const getToken = async () => {
				const token = await firebaseAppAuth.currentUser.getIdToken();
				setToken(token);
			};

			if (user) getToken();
		},
		[ user ]
	);

	if (loading && !token) return <Spinner />;
	if (error) return <Error error={error} />;

	console.log(token);

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: new HttpLink({
			headers: {
				authorization: token
			},
			uri: '/.netlify/functions/graphql'
			// uri: process.env.REACT_APP_API
		})
	});

	return <ApolloProvider client={client}>{token ? <Account /> : <Auth />}</ApolloProvider>;
}
