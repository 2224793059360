import React, { useContext, useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import Hero from 'components/ui/Hero';
import Cards from 'components/ui/Cards';
import Error from 'components/ui/Error';
import Spinner from 'components/ui/Spinner';

export default function Consumers({ itemID }) {
	const [ tab, setTab ] = useState('inventory');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(itemID);

	const { loading, data, error } = useQuery(
		CONSUMERS,
		{
			// variables: { email: user.email },
		}
	);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	return (
		<div>
			<Hero
				color="light"
				icon="user-friends"
				title="Customers"
				// subtitle="Your customers by status"
				tabs={tabs}
				activeTab={tab}
				setTab={setTab}
				view={view}
				setView={setView}
			/>
			<Cards type="consumers" data={data.consumers} />
		</div>
	);
}

const tabs = [
	{ title: 'new', icon: 'cart-arrow-down' },
	{ title: 'interested', icon: 'dollar-sign' },
	{ title: 'dropped', icon: 'archive' },
	{ title: 'purchased', icon: 'check-circle' }
];

const CONSUMERS = gql`
	query Consumers {
		consumers {
			id
			email
			displayName
			avatar
		}
	}
`;
