import React, { useEffect, useState } from 'react';
import { FastField, useFormikContext } from 'formik';
import axios from 'axios';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Field, div, Level, Item } from 'components/ui/bulma';
import { Input } from 'components/ui/bulma';

export default function Description() {
	const [ loading, setLoading ] = useState(false);
	const { values, setValues, setFieldTouched } = useFormikContext();

	return (
		<div>
			<ReactQuill
				style={{ height: '20vh' }}
				theme="snow"
				value={values.description}
				onChange={(value) => setValues({ description: value })}
			/>
			<br />
			<br />
			<br />
			<br />
		</div>
	);
}
